import React from 'react';
import {
  ApolloProvider,
} from '@apollo/client';

import { LandingPage } from './pages/LandingPage';

import { client } from './graphql';

/**
 * This is the root of the application.
 *
 * @returns The application
 */
function App() {
  return (
    <ApolloProvider client={client}>
      <LandingPage />
    </ApolloProvider>
  );
}

export default App;
